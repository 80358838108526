import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import Vercel from '../routes/vercel';
import Others from '../routes/others';
import Drives from '../routes/drives';
import Index from '../routes/index';
import TeamDrive from '../routes/td';
import Bookmarks from '../routes/bookmarks';
import IndexTwo from '../routes/index-two';

const App = () => (
	<div id="app">
		<Header />
		<Router>
			<Home path="/" />
			<Profile path="/profile/" user="me" />
			<Profile path="/profile/:user" />
			<Drives path="/drive-one" user="me" />
			<Others path="/dp" />
			<Index path="/indexer" user="me" />
			<IndexTwo path="/index2" user="me" />
			<Vercel path="/vercel" />
			<TeamDrive path="/td" />
			<Bookmarks path="/bookmarks" />
		</Router>
	</div>
)

export default App;
